import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useState } from 'react';
import axios from 'axios';
import { sendEmailWithPostmark } from '../../api.controller';

function Contactform() {

    const [data, setData ] = useState({
        name: '',
        email: '',
        phone:'',
        message: ''
    });
    
    const handleChange = (event) =>{
        const {value,name} = event.target;
        setData({...data,[name]:value});
    }

    useEffect(()=>{
        console.log('data==>', data);
    },[data]);
   
    const _sendEmailWithPostmark = async () => {
        try {
            const response = await sendEmailWithPostmark({...data, sender: 'notifications@careersinghana.com'});
            console.log('Email sent successfully:', response);
        } catch (error) {
            console.error('Error sending email:', error);
        }

    };

    return (
        <div>
            <section className="contact-one pt-100 pb-100">
                <div className="container">
                    <div className="contact-one__inner">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="contact-one__content">
                                    <div className="sec-title text-@@textAlign">
                                        <h6 className="sec-title__tagline">Contact us</h6>
                                        <h3 className="sec-title__title">
                                            Feel Free to Get in Touch <br /> with Careers In Ghana
                                        </h3>
                                    </div>
                                    <p className="contact-one__text">
                                        Ready to take the next step in your career journey? Reach out to Careers In Ghana and unlock exciting opportunities tailored to your aspirations. Our team is here to guide you towards fulfilling your professional goals. Get in touch today and embark on a path towards success!
                                    </p>
                                    <ul className="list-unstyled contact-one__info">
                                        <li className="contact-one__info__item">
                                            <div className="contact-one__info__icon">
                                                <i className="icon-phone-call-1" />
                                            </div>
                                            <div className="contact-one__info__content">
                                                <p className="contact-one__info__text">Have Question?</p>
                                                <h4 className="contact-one__info__title">
                                                    <Link to="tel:+233500043033">(+233)500043033 /(0)500865676</Link>
                                                </h4>
                                            </div>
                                        </li>
                                        <li className="contact-one__info__item">
                                            <div className="contact-one__info__icon">
                                                <i className="icon-message" />
                                            </div>
                                            <div className="contact-one__info__content">
                                                <p className="contact-one__info__text">Write Email </p>
                                                <h4 className="contact-one__info__title">
                                                    <Link to="mailto:employers@careersinghana.com">
                                                        employers@careersinghana.com
                                                    </Link>
                                                </h4>
                                            </div>
                                        </li>
                                        <li className="contact-one__info__item">
                                            <div className="contact-one__info__icon">
                                                <i className="icon-placeholder" />
                                            </div>
                                            <div className="contact-one__info__content">
                                                <p className="contact-one__info__text">Visit Anytime</p>{" "}
                                                <h4 className="contact-one__info__title">
                                                    <Link to="#">VeroStina House, 2nd Floor, Off Spintex Road,
                                                        Comm 18 (Opp DSTV office, Near Comm 18 Police Station) Spintex Road - Accra Ghana</Link>
                                                </h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="contact-one__left">
                                    <form className="contact-one__form contact-form-validated form-one background-base wow fadeInUp" data-wow-duration="1500ms" action="inc/sendemail.php">
                                        <div className="form-one__group">
                                            <div className="form-one__control form-one__control--full">
                                                <input type="text" onChange={handleChange} name="name" placeholder="Your name" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="email" onChange={handleChange} name="email" placeholder="Email address" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <input type="text" onChange={handleChange} name="phone" placeholder="Phone number" />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <textarea name="message" onChange={handleChange} placeholder="Write  a message" defaultValue={""} />
                                            </div>
                                            <div className="form-one__control form-one__control--full">
                                                <button type="submit" onClick={(event)=>{
                                                    event.preventDefault();
                                                    _sendEmailWithPostmark();
                                                }}  className="hiredots-btn hiredots-btn--white">
                                                    <span>send a message</span>
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Contactform