// Authentication

import axios from 'axios';

const handleAxiosError = (error, defaultMessage = "") => {
    return error?.response?.data?.message || defaultMessage || "An error occured";
}

export const login = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/auth/signin`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to login! Please try again"));
    }
}
export const loginAdmin = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/admins/auth/signin`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to login! Please try again"));
    }
}
export const register = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/users`;
        const payload = new FormData();
        Object.keys(data).forEach(key => {
            payload.append(key, data[key]);
        });
        const response = await axios.post(url, payload);
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to register! Please try again"));
    }
}

export const logout = async () => {

}



//Jobs
export const getJobs = async (filter, category) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            params: { ...filter, category }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch jobs! Please try again"));
    }
}
export const getJobDetailsById = async (id) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs/${id}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch job details! Please try again"));
    }
}

export const getBlogDetails = async (id) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/blogs/${id}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch blog details! Please try again"));
    }
}
export const getJobCategories = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/job-categories`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch job categories! Please try again"));
    }
}

export const createJob = async (data) => {
    const { token } = JSON.parse(localStorage.getItem("userData"));

    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create job! Please try again"));
    }
}
export const updateJob = async (id, data) => {
    const { token } = JSON.parse(localStorage.getItem("userData"));

    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs/${id}`;
        const response = await axios.put(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to update job! Please try again"));
    }
}

export const deleteJob = async (jobId) => {
    const { token } = JSON.parse(localStorage.getItem("userData"));

    try {
        const url = `${process.env.REACT_APP_BASE_URL}/jobs/${jobId}`;
        const response = await axios.delete(url, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to delete job! Please try again"));
    }
}

export const sendEmailWithPostmark = async (data)=>{
    const { token } = JSON.parse(localStorage.getItem("userData"));
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/emails`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to send email! Please try again"));
    }
}

export const createCareerListing = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/career-listings`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create career listing! Please try again"));
    }
}
export const createNewAdmin = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/admins`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create admin! Please try again"));
    }
}
export const createGalleryPicture = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/gallery-pictures`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create gallery picture! Please try again"));
    }
}

export const getStrapiData = async (endpoint) => {
    try {
        const url = `${process.env.REACT_APP_CMS_BASE_API}/${endpoint}`;
        console.log(url);
        const response = await axios.get(url);
        return response
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to get strapi data"))
    }
}


export const getJobSeekersWithResume = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/users/job-seekers`;
        console.log(url);
        const response = await axios.get(url);
        return response
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to get jobseekers"));
    }
}

export const getUsers = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/users`;
        console.log(url);
        const response = await axios.get(url);
        return response
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to get users"));
    }
}
export const getAdmins = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/admins`;
        console.log(url);
        const response = await axios.get(url);
        return response
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to get admins"));
    }
}


//Blogs
export const createBlog = async (data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/blogs`;
        const response = await axios.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to create blog! Please try again"));
    }
}

export const editBlog = async (data) => {

}

export const deleteBlog = async (blogId) => {

}

export const updateUserFile = async (id, data) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/users/${id}`;
        const payload = new FormData();
        Object.keys(data).forEach(key => {
            payload.append(key, data[key]);
        });
        const response = await axios.patch(url, payload);
        return response?.data || null;
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to update file! Please try again"));
    }
}

export const getAllBlogs = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/blogs`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch blogs! Please try again"));
    }
}
export const getAllCareerListingData = async (_selectedCategory) => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/career-listings?category=${_selectedCategory}`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch career listings! Please try again"));
    }
}
export const getAllGalleryPictures = async () => {
    try {
        const url = `${process.env.REACT_APP_BASE_URL}/gallery-pictures`;
        const response = await axios.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data || null
    } catch (error) {
        throw new Error(handleAxiosError(error, "Unable to fetch gallery pictures! Please try again"));
    }
}

